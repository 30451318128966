import {keyframes,css} from "styled-components"

export const upElementKeyframes = keyframes`
  80%{
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }  
`
export const upElement = ({ time = "2s" } = {}) => css`
  animation: ${upElementKeyframes} ${time} ease-out forwards;
  animation-delay: 2s;
`

export const showKeyframes = keyframes`
  100% {
    transform: rotateX(0);
  }  
`
export const show = ({ time = "2s" } = {}) => css`
  animation: ${showKeyframes} ${time} forwards;
  transform-origin: top;
`

export const onKeyframes = keyframes`
  0%{
    fill: #011a4d;
  }
  50%{
    fill: white;
  }
  70%{
    fill: #011a4d;
  }
  80%{
    fill: white;
  }
  90%{
    fill: #011a4d;
  }
  100% {
    fill: white;
  }  
`
export const on = ({ time = "2s" } = {}) => css`
  animation: ${onKeyframes} ${time} infinite;
`