import { Link } from "gatsby"
import React from "react"
import { useTranslate } from "react-translate"
import { css } from "styled-components"
import { useLang } from "../hooks/useLang"
import { Grid } from "../styles"
import {
  PortfolioHeader,
  PortfolioHomeContainer,
} from "../styles/components/PortfolioHome"
import { colorFluerecente } from "../styles/variables"
import PorfolioHomeCard from "./PorfolioHomeCard"

export default function PortfolioHome({
  className,
  title,
  projects,
  to,
  caption,
}) {
  const [lang, setLang] = useLang()
  const portfolioTexts = useTranslate("portfolioHome")

  return (
    <PortfolioHomeContainer className={className}>
      <PortfolioHeader>
        <h3>{title}</h3>
        <h4>{caption}</h4>
        <Link
          css={css`
            color: ${colorFluerecente};
          `}
          to={`${lang === "en" ? "" : "/es"}${to}`}
        >
          {portfolioTexts("seeMore")}{" "}
        </Link>
      </PortfolioHeader>

      <Grid columns={3} gap={2} style={{ marginTop: "4em" }}>
        {projects.map(({ node }, index) => (
          <PorfolioHomeCard key={index} {...node} />
        ))}
      </Grid>
    </PortfolioHomeContainer>
  )
}
