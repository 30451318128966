import React from "react"
import { css } from "styled-components"
import { PortfolioHomeCardContainer } from "../styles/components/PortfolioHomeCard"
import Image from "./Image"
import { useLang } from "../hooks/useLang"
import Img from "gatsby-image"

export default function PorfolioHomeCard(props) {
  const { Image, ProjectInfoEn, ProjectInfoEs, project_types } = props
  const name = props.Name

  const [lang, setLang] = useLang()

  const { Name } = lang === "en" ? ProjectInfoEn : ProjectInfoEs

  const url = name.toLowerCase().split("-").join("").split(" ").join("-")

  return (
    <PortfolioHomeCardContainer
      to={`${lang === "en" ? "" : "/es"}/portfolio/${
        project_types[0].Name === "iot" ? "" : project_types[0].Name
      }#${url}`}
    >
      <Img
        css={css`
          height: 200px;
          & img {
            height: 200px !important;
            object-fit: contain !important;
          }
          @media (max-width: 500px) {
            height: 100px;
            & img {
              height: 100% !important;
            }
          }
        `}
        fluid={Image.localFile.childImageSharp.fluid}
      />
      <h4>{Name}</h4>
    </PortfolioHomeCardContainer>
  )
}
