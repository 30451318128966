import React from "react"
import { usePosition } from "../hooks/usePosition"
import {
  ProcessCardContainer,
  ProcessImageCard,
} from "../styles/components/ProcessCard"
import Image from "./Image"
import Img from "gatsby-image"

export default function ProcessCard({ title, image, descripcion }) {
  const isActive = usePosition(ProcessCardContainer)

  console.log(image)

  return (
    <ProcessCardContainer animate={isActive}>
      <h3>{title}</h3>
      <ProcessImageCard>
        <Img fluid={image.localFile.childImageSharp.fluid} />
      </ProcessImageCard>
      <p>{descripcion}</p>
    </ProcessCardContainer>
  )
}
