import styled from 'styled-components';
import { show } from '../animations';
import { colorPrincipal } from '../variables';

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6em;
  height: 200px;

  & svg{
      position: absolute;
      top: 0;
      left: -.5em;
  }

  & h3{
      position: relative;
      z-index: 500;
      color: ${colorPrincipal};
      text-align: center;
      font-size: 1.2rem;
      font-size: 900;
      margin-right: ${props => !props.left ? '1.5em' : '' };
      transform: rotate(${props => props.left ? '3deg' : '-3deg'})
  }
  @media (max-width: 500px){
    padding: 0;
    height: auto;

    & h3{
      font-size: 1rem;
      padding: 1em 1em;
      width: 90%;
      /* width: 50vw; */
    }
  }
`;