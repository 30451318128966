import React, { useEffect, useState } from "react"
import { Grid } from "../styles"
import {
  From,
  FromImage,
  Person,
  ReedMore,
  TestimonialLogo,
} from "../styles/components/Testimonial"
import { colorPrincipal } from "../styles/variables"
import Image from "./Image"
import { css } from "styled-components"
import ReactHtmlParser from "react-html-parser"
import { useLang } from "../hooks/useLang"
import Img from "gatsby-image"

export default function Testimonial(props) {
  const {
    className,
    from,
    name,
    imageFlag,
    imagePerson,
    setHeightTestimonial,
  } = props
  console.log(props)
  // {className, en,name, picture, setHeightTestimonial,flag,from}
  const [lang, setLang] = useLang()

  let descriptionShort, description

  if (lang === "en") {
    description = props.testimonial
    descriptionShort = props.testimonialShort
  } else {
    description = props.testimonio
    descriptionShort = props.testimonioCorto
  }

  const [testimonial, setTestimonial] = useState(descriptionShort)
  const [isChangeTestimonial, setIsChangeTestimonial] = useState(false)
  const [reed, setReed] = useState("Reed More")
  let $testimonialActive
  useEffect(() => {
    $testimonialActive = document.querySelector("#testimonials .active")
    setHeightTestimonial($testimonialActive.offsetHeight)
  }, [isChangeTestimonial])

  const handleDescription = () => {
    if (!isChangeTestimonial) {
      setTestimonial(description)
      setReed("Reed Less")
    } else {
      setTestimonial(descriptionShort)
      setReed("Reed More")
    }
    setIsChangeTestimonial(!isChangeTestimonial)
  }

  return (
    <Grid
      className={className}
      columns={2}
      gap={7}
      responsive={`
                gap: 2em;
            `}
    >
      <div
        css={css`
          align-self: center;
        `}
      >
        <p
          css={css`
            margin-top: 8em;
            color: ${colorPrincipal};

            @media (max-width: 500px) {
              margin-top: 0;
            }
          `}
        >
          {ReactHtmlParser(testimonial)}
        </p>
        <Person>{name}</Person>
        <ReedMore onClick={handleDescription}>{reed}</ReedMore>
      </div>
      <div>
        <From>
          <FromImage>
            <Img fluid={imageFlag.localFile.childImageSharp.fluid} />
          </FromImage>
          <span>{from}</span>
        </From>
        <TestimonialLogo>
          <Img fluid={imagePerson.localFile.childImageSharp.fluid} />
        </TestimonialLogo>
      </div>
    </Grid>
  )
}
