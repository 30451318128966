import * as React from "react"

function CardLeft(props) {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox="0 0 289 254"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M259.383 221.168l3.983-186.857-210.635-4.297-4.011 188.013-.099 6.07c.847.333 1.58.883 2.116 1.588.401-.732 1.654-1.334 2.004-1.922.36.36 5.13 3.251 5.133 2.885.004-1.912 2.707-2.488 4.727-2.44 9.926.204 19.834.733 29.724 1.59l1.135-.445c7.924 1.078 16.004.063 23.355-2.936.733 1.654 2.626 2.549 4.456 2.993 3.882.912 7.98.428 11.505-1.36l5.479 2.759a615.116 615.116 0 013.124-3.715c.988 1.26 2.837 1.527 4.491 1.692l5.177.515c1.932.195 4.18.282 5.483-1.087.354 2.48 3.776 3.355 6.367 2.895 2.591-.46 5.175-1.653 7.739-1.06 2.022.47 3.666 2.007 5.724 2.289 2.336.319 4.514-1.026 6.73-1.745 2.216-.718 5.365-.398 6.037 1.752l2.306-2.288a5.488 5.488 0 003.496.664 5.332 5.332 0 003.136-1.607 10.744 10.744 0 004.896 1.555 10.837 10.837 0 005.075-.88 7.76 7.76 0 012.652-.963c2.2-.134 3.967 2.276 6.216 2.156 1.077-.06 1.995-.701 2.947-1.185 1.9-.929 4.053-1.29 6.174-1.034a10.869 10.869 0 015.711 2.467c.95-1.943 2.551-3.533 4.553-4.521.21 2.66 4.933 3.438 6.088 1.027l2.611 2.933c.74-1.245 2.581-1.263 4.078-1.12l10.258.99.059-5.371z"
        fill="#CFD0D2"
      />
      <path
        d="M57.126 213.446c1.73.437 2.974 2.071 4.743 2.293 1.502.205 2.917-.699 4.428-.886 1.224-.064 2.45.09 3.616.454a77.366 77.366 0 0024.008 1.905c1.985-.15 4.044-.406 5.674-1.488 1.21-.795 2.103-1.998 3.424-2.618 1.322-.621 3.119-.54 4.703-.393l50.455 4.697c23.733 2.192 47.647 4.382 71.358 2.075 8.85-.86 17.852-2.342 26.692-1.491l3.985-186.877L49.577 26.82l-4.01 188.013 8.632-1.297a7.346 7.346 0 012.927-.09z"
        fill="#F5F8FA"
      />
      <path
        d="M47.555 222.487c.401-.732 1.654-1.334 2.004-1.921.36.36 5.13 3.25 5.133 2.884.004-1.912 2.707-2.488 4.727-2.439a451.73 451.73 0 0129.724 1.589l1.136-.445c7.923 1.078 16.003.063 23.355-2.935.732 1.654 2.625 2.548 4.455 2.992 3.882.912 7.98.428 11.505-1.36l5.479 2.76a621.19 621.19 0 013.124-3.716c.988 1.261 2.837 1.528 4.491 1.692l5.177.515c1.933.196 4.18.282 5.483-1.086.354 2.479 3.776 3.355 6.367 2.895 2.591-.461 5.176-1.654 7.739-1.061 2.022.47 3.666 2.007 5.724 2.289 2.336.32 4.515-1.026 6.73-1.744 2.216-.719 5.365-.399 6.038 1.751l2.306-2.287a5.49 5.49 0 003.495.664 5.343 5.343 0 003.136-1.607 10.838 10.838 0 009.971.674 7.81 7.81 0 012.652-.963c2.2-.134 3.967 2.277 6.216 2.156 1.078-.059 1.995-.7 2.947-1.185a11.064 11.064 0 016.174-1.033 10.869 10.869 0 015.711 2.467c.951-1.944 2.551-3.534 4.553-4.522.211 2.66 4.934 3.438 6.088 1.027l2.611 2.933c.74-1.245 2.581-1.262 4.079-1.119l10.257.989.11-5.323c-8.84-.851-17.812.628-26.691 1.491-23.707 2.28-47.626.117-71.358-2.075l-50.461-4.704c-1.584-.148-3.273-.275-4.703.392-1.43.667-2.215 1.824-3.424 2.618-1.617 1.084-3.69 1.339-5.674 1.488a77.366 77.366 0 01-24.008-1.905 10.354 10.354 0 00-3.617-.453c-1.482.19-2.922 1.071-4.428.885-1.773-.243-3.02-1.857-4.742-2.293a7.381 7.381 0 00-2.917.064l-8.636 1.324-.125 6.053a4.91 4.91 0 012.087 1.584z"
        fill="#CAD8E3"
      />
      <path
        opacity={0.8}
        d="M87.986 26.284c-.09-.371-.262-.726-.376-1.08a6.02 6.02 0 01-.145-1.498c-.085-2.242-.205-.719-.077-2.973.006-.21.038-.42.094-.623l-57.907 1.942a1.003 1.003 0 00-.03.203 9.16 9.16 0 00.204 1.83c.295 2.533.369 5.083.22 7.627a6.132 6.132 0 00.078 1.944c.091.364.255.725.362 1.078.124.536.178 1.083.161 1.631.03 2.076.102 4.158.096 6.228l57.878-1.897a89.76 89.76 0 01-.472-12.764 5.15 5.15 0 00-.087-1.648z"
        fill="#01C8F0"
      />
    </svg>
  )
}

export default CardLeft
