import styled from "styled-components"
import { colorFluerecente } from "../variables"

export const ProcessCardContainer = styled.div`
  text-align: center;
  color: white;
  position: relative;
  & p {
    color: white;
    margin-top: 1em;
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  &:first-child {
    &:before {
      content: "";
      position: absolute;
      top: 6.5em;
      left: 6em;
      width: ${props => (props.animate ? "340%" : "0%")};
      height: 2px;
      background: ${colorFluerecente};
      transition: 1s;

      @media (max-width: 500px) {
        display: none;
      }
    }
  }
`

export const ProcessImageCard = styled.div`
  width: 100px;
  margin: 0 auto;
`
