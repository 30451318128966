import styled, { css, keyframes } from "styled-components"
import { colorOscuro, fuenteTitulos } from "../variables"
import { Link } from "gatsby"

const timing = 0.5

const expandCard = () => css`
  transform: translate(-10px, -10px);
  box-shadow: 19px 18px 0 0 #aaaaba;
`
const expandCardAnimationKeyframes = keyframes`
  50%{
    ${expandCard()}
  }
  100%{
    transform: translate(0, 0);
    box-shadow: 9px 8px 0 0 #aaaaba;
  }
`

const expandCardAnimation = ({ time = `${timing}s` } = {}) => css`
  animation: ${expandCardAnimationKeyframes} ${time};
`

export const ServiceCardContainer = styled(Link)`
  background: ${colorOscuro};
  text-align: center;
  padding: 2em 2em;
  border-radius: 10px;
  box-shadow: 9px 8px 0 0 #aaaaba;
  box-sizing: border-box;
  text-decoration: none;
  ${props => props.animate && expandCardAnimation()};

  & h3 {
    color: white;
    font-family: ${fuenteTitulos};
    font-size: 1rem;
    text-transform: uppercase;
    margin-top: 1em;
  }
  &:hover {
    ${expandCard()}
  }

  &:nth-of-type(2) {
    animation-delay: ${timing}s;
  }
  &:nth-of-type(3) {
    animation-delay: ${timing * 2}s;
  }
  &:nth-of-type(4) {
    animation-delay: ${timing * 3}s;
  }
`

export const ImageService = styled.div`
  height: 80px;
  margin: 0 auto;
  & img {
    width: auto !important;
    height: 80px !important;
    margin: 0 auto;
  }
`
