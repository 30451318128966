import styled from "styled-components"
import { Grid } from ".."
import {
  colorFluerecente,
  colorPrincipal,
  fuentePrincipal,
  fuenteTitulos,
} from "../variables"

export const Caption = styled.h3`
  font-family: ${fuentePrincipal};
  font-weight: normal;
  font-size: 1.5rem;
  font-weight: normal;
  & strong {
    color: ${colorFluerecente};
    font-weight: normal;
  }
`

export const International = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & h4 {
    font-family: ${fuentePrincipal};
    font-weight: lighter;
    text-transform: uppercase;
    font-size: 2rem;
    margin-bottom: 0;
    & strong {
      font-size: 3.5rem;
    }

    @media (max-width: 500px) {
      font-size: 1.5rem;
    }
  }
`

export const SuccessDescription = styled.p`
  color: ${colorPrincipal};
`

export const InternationalTitle = styled.h4`
  margin: 0;
  color: ${colorPrincipal};
  font-family: ${fuenteTitulos};
  font-weight: lighter;

  &:nth-of-type(2) {
    margin-top: -1rem;
  }
`

export const ContactSocial = styled.div`
  display: flex;
  margin: 1em 0 0 2em;

  & a {
    color: ${colorPrincipal};
    font-size: 1.5rem;
    margin-right: 1em;
  }
`

export const ServicesContainer = styled(Grid)`
  margin-top: 4em;
  padding: 0 12em;

  @media (max-width: 500px) {
    padding: 0 2em;
  }
`

export const InnovateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 500px) {
    max-width: calc(100vw - 4em);
  }
`

export const InnovateDescription = styled.p`
  margin: 0 0 2em;
  color: black;
  line-height: 1.5em;

  @media (max-width: 500px) {
    font-size: inherit;
  }
`
