import { graphql, useStaticQuery } from "gatsby"

export const useProcess = lang => {
  const process = useStaticQuery(
    graphql`
      query {
        allStrapiProcess {
          edges {
            node {
              id
              Name
              Nombre
              Description
              Descripcion
              Image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  if (lang === "en") {
    process.allStrapiProcess.edges.forEach(function (item, index) {
      item.node.name = item.node.Name
      item.node.description = item.node.Description
    })
  } else {
    process.allStrapiProcess.edges.forEach(function (item, index) {
      item.node.name = item.node.Nombre
      item.node.description = item.node.Descripcion
    })
  }

  return process.allStrapiProcess.edges
}
