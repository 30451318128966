import { graphql, useStaticQuery } from "gatsby"

export const usePartners = () => {
  const partner = useStaticQuery(
    graphql`
      query {
        allStrapiPartners {
          edges {
            node {
              Name
              Image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return partner.allStrapiPartners.edges
}
