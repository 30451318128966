import { graphql, useStaticQuery } from "gatsby"

export const useServices = lang => {
  const services = useStaticQuery(
    graphql`
      query {
        allStrapiServices {
          edges {
            node {
              id
              Name
              NameSpanish
              ImagePrincipal {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  if (lang === "en") {
    services.allStrapiServices.edges.forEach(function (item, index) {
      item.node.name = item.node.Name
    })
  } else {
    services.allStrapiServices.edges.forEach(function (item, index) {
      item.node.name = item.node.NameSpanish
    })
  }

  return services.allStrapiServices.edges
}
