import {useState, useEffect} from 'react'

export const usePosition = (Component)=>{

    const [isAnimate, setIsAnimate] = useState(false)
    
    useEffect(() => {
        document.addEventListener('scroll',viewPosition)

        function viewPosition(){
            const itemClass = Component.styledComponentId
            const $item = document.querySelectorAll(`.${itemClass}`);
            if($item[0]!== 'undefined'){
                const position = $item[0].getBoundingClientRect().top
                const condition = position < 500
                
                if(condition){
                    setIsAnimate(true)
                }
            }
        }

        return ()=>{
            document.removeEventListener('scroll',viewPosition)
        }
    })

    return isAnimate
}

