import * as React from "react"

function CardRight(props) {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox="0 0 293 259"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.366 226.235L24.737 39.521l210.469-9.066 8.686 187.869.249 6.067a4.897 4.897 0 00-2.076 1.635c-.418-.723-1.686-1.297-2.051-1.876-.351.368-5.046 3.366-5.06 3-.051-1.911-2.768-2.426-4.786-2.332a450.86 450.86 0 00-29.676 2.263l-1.146-.42a45.677 45.677 0 01-23.421-2.405c-.691 1.67-2.562 2.607-4.38 3.092-3.858 1-7.967.608-11.536-1.099l-5.409 2.883a594.862 594.862 0 00-3.215-3.644c-.956 1.283-2.798 1.591-4.448 1.793l-5.162.632c-1.928.24-4.172.377-5.509-.961-.292 2.486-3.691 3.439-6.293 3.038-2.602-.402-5.215-1.536-7.763-.885-2.01.516-3.616 2.089-5.666 2.418-2.327.372-4.539-.923-6.771-1.592-2.233-.668-5.374-.276-5.993 1.888l-2.362-2.235a5.48 5.48 0 01-3.478.743 5.344 5.344 0 01-3.175-1.535 10.721 10.721 0 01-4.856 1.665 10.84 10.84 0 01-5.095-.765 7.818 7.818 0 00-2.675-.903c-2.203-.084-3.91 2.366-6.161 2.297-1.079-.036-2.012-.656-2.976-1.118a11.072 11.072 0 00-6.198-.894 10.838 10.838 0 00-5.648 2.596c-.998-1.922-2.638-3.475-4.664-4.417-.144 2.664-4.847 3.548-6.06 1.164l-2.538 2.992c-.77-1.228-2.612-1.204-4.105-1.027l-10.23 1.222-.193-5.369z"
        fill="#CFD0D2"
      />
      <path
        d="M235.374 213.935c-1.718.476-2.922 2.138-4.684 2.399-1.497.24-2.934-.632-4.449-.784a10.309 10.309 0 00-3.604.535 77.333 77.333 0 01-23.954 2.448c-1.988-.104-4.053-.314-5.71-1.359-1.229-.767-2.151-1.95-3.488-2.54-1.336-.59-3.131-.469-4.711-.285l-50.325 5.838c-23.671 2.728-47.524 5.459-71.286 3.69-8.868-.66-17.904-1.937-26.72-.886L27.81 36.257l210.47-9.067 8.686 187.869-8.662-1.102a7.369 7.369 0 00-2.929-.023z"
        fill="#F5F8FA"
      />
      <path
        d="M245.167 222.757c-.419-.723-1.687-1.296-2.051-1.876-.351.368-5.047 3.366-5.06 3-.052-1.911-2.768-2.426-4.787-2.332a450.966 450.966 0 00-29.676 2.263l-1.146-.42a45.668 45.668 0 01-23.421-2.405c-.691 1.67-2.561 2.607-4.38 3.092-3.858 1-7.967.609-11.535-1.099l-5.409 2.883a630.822 630.822 0 00-3.216-3.644c-.956 1.283-2.798 1.592-4.447 1.793l-5.163.633c-1.927.239-4.171.376-5.508-.962-.293 2.486-3.692 3.439-6.293 3.038-2.602-.401-5.216-1.536-7.764-.885-2.01.516-3.615 2.09-5.665 2.418-2.327.372-4.539-.923-6.772-1.592-2.233-.668-5.373-.276-5.992 1.888l-2.362-2.234a5.488 5.488 0 01-3.479.742 5.344 5.344 0 01-3.174-1.535 10.721 10.721 0 01-4.856 1.665 10.84 10.84 0 01-5.096-.765 7.818 7.818 0 00-2.675-.903c-2.203-.084-3.91 2.366-6.16 2.297-1.08-.036-2.013-.656-2.977-1.118a11.072 11.072 0 00-6.197-.894 10.838 10.838 0 00-5.648 2.596c-.999-1.922-2.639-3.475-4.664-4.417-.145 2.664-4.847 3.548-6.061 1.164l-2.538 2.992c-.77-1.228-2.611-1.204-4.105-1.027l-10.23 1.222-.242-5.319c8.816-1.051 17.822.224 26.72.886 23.758 1.742 47.616-.962 71.287-3.691l50.33-5.846c1.58-.183 3.265-.349 4.711.286 1.447.635 2.26 1.773 3.488 2.54 1.643 1.047 3.722 1.255 5.71 1.359a77.333 77.333 0 0023.954-2.448 10.333 10.333 0 013.604-.536c1.486.157 2.948 1.005 4.449.785 1.766-.282 2.973-1.924 4.684-2.399a7.364 7.364 0 012.917-.003l8.667 1.129.275 6.048a4.891 4.891 0 00-2.047 1.631z"
        fill="#CAD8E3"
      />
      <path
        opacity={0.8}
        d="M199.868 27.524c.081-.373.244-.732.349-1.088a6 6 0 00.108-1.5c.029-2.244.187-.724.002-2.974a2.57 2.57 0 00-.109-.62l57.939.629a.995.995 0 01.036.202 9.259 9.259 0 01-.159 1.835 43.877 43.877 0 00-.031 7.629 6.106 6.106 0 01-.03 1.945c-.081.367-.236.731-.334 1.086a6.407 6.407 0 00-.121 1.635c.022 2.075.002 4.158.059 6.228l-57.909-.586c.355-4.249.407-8.516.155-12.77a5.162 5.162 0 01.045-1.65z"
        fill="#01C8F0"
      />
    </svg>
  )
}

export default CardRight
