import styled from 'styled-components';
import { Grid } from '..';
import {PortfolioHomeCardContainer} from '../components/PortfolioHomeCard';
import { colorOscuro, fuenteTitulos } from '../variables';

export const PortfolioHomeContainer = styled.div`
  display: flex;
  flex-direction: column;


  @media (max-width: 500px){
    
    & ${Grid} ${PortfolioHomeCardContainer}:nth-of-type(2),& ${Grid} ${PortfolioHomeCardContainer}:nth-of-type(3){
      display: none;
    }
  }
`;

export const PortfolioHeader = styled.div`
  background: ${colorOscuro};
  width: 60%;
  align-self: flex-end;
  color: white;
  padding: 2em 3em;
  border-radius: 8px;

  & h3{
      text-transform: uppercase;
      font-family: ${fuenteTitulos};
      font-size: 2rem;
  }

  & h4{
    font-family: ${fuenteTitulos};
  }
  @media (max-width: 500px){
    width: 100%;
    & h3{
      font-size: 1.5rem;
    }
  }
`;
