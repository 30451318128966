import styled from 'styled-components';
import { colorClaro, colorOscuro, colorPrincipal } from '../variables';

export const ButtonContainer = styled.div`
  width: 110%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  transition: .3s;
  @media (max-width: 500px){
    width: 100%;
  }
`;

export const BannerContainer = styled.div`
  position: relative;
  /* overflow: hidden; */
  display: flex;
  justify-content: center;
  padding: 2em 0;
  align-items: center;
  ${props => props.styles};
`;

export const BannerButton = styled.div`
  transition: .3s;
  & button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1em .5em;
    border: 1px solid ${colorPrincipal};
    border-radius: 2px;
    background: ${colorClaro};
    transition: .3s;
    cursor: pointer;
    visibility: ${props => !props.isThere && 'hidden'};
    & svg{
      transition: 0s;
    }
    &:hover{
        background: ${colorOscuro};
        color: white;
    }
    @media (max-width: 700px){

    }
    
  }
`;


export const BannerItems = styled.div`
  width: 100%;
  position: relative;
  height: ${props => props.height+ 'px'};
  & > div{
      position: absolute;
      top: 0;
      left: 100vw;
      width: 100%;
      transition: .3s;
      /* display: contents; */
      &.active{
          left: 0;
      }
  }
`;

