import * as React from "react"
import { useState } from "react"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import { Button, Container, Grid, PartnerImage } from "../styles"
import {
  colorClaro,
  colorOscuro,
  colorPrincipal,
  fuentePrincipal,
  fuenteTitulos,
} from "../styles/variables"
import ServiceCard from "../components/ServiceCard"
import InnovateCard from "../components/InnovateCard"
import Map from "../assets/Map"
import ProcessCard from "../components/ProcessCard"
import Image from "../components/Image"
import Banner from "../components/Banner"
import PortfolioHome from "../components/PortfolioHome"
import Testimonial from "../components/Testimonial"
import { FaWhatsapp, FaRegEnvelope, FaPhoneAlt } from "react-icons/fa"
import {
  Caption,
  International,
  SuccessDescription,
  InternationalTitle,
  ContactSocial,
  ServicesContainer,
  InnovateDescription,
  InnovateContainer,
} from "../styles/pages"
import HomeIllustration from "../assets/HomeIllustration"
import { css } from "@emotion/react"
import { projects } from "../api/db.json"
import { usePosition } from "../hooks/usePosition"
import { usePartners } from "../hooks/usePartners"
import { useProjects } from "../hooks/useProjects"
import { useTestimonials } from "../hooks/useTestimonials"
import { useItems } from "../hooks/useItems"
import Counter from "../components/Counter"
import { useTranslate } from "react-translate"
import ReactHtmlParser from "react-html-parser"
import marked from "marked"
import Img from "gatsby-image"

const Home = ({ info, buttonText, services, process }) => {
  const [heightTestimonial, setHeightTestimonial] = useState(500)

  const partners = usePartners()
  const testimonials = useTestimonials()
  const contactItems = useItems("contactItems").content[0]

  const homeText = info.Cuerpo[0]

  const findProject = type => {
    return projects
      .filter(projectData => {
        return projectData.type.includes(type)
      })
      .slice(0, 3)
  }

  const isInternationalActive = usePosition(InternationalTitle)

  const projectIot = useProjects("iot").slice(0, 3)
  const projectMovil = useProjects("mobile").slice(0, 3)
  const projectWeb = useProjects("web").slice(0, 3)
  const projectCasing = useProjects("casing").slice(0, 3)

  //translate

  const homeTexts = useTranslate("heroHomePage")
  const innovateText = useTranslate("innovateHome")
  const internationalText = useTranslate("internationalHome")
  const portfolioText = useTranslate("portfolioHome")
  const contactText = useTranslate("contactHome")

  return (
    <>
      <Seo title="Home" />
      <Hero
        title={homeText.TitleHero}
        Svg={HomeIllustration}
        animation={true}
        columns={"1fr 1fr"}
        home={true}
      >
        <Caption>{ReactHtmlParser(marked(homeText.DescriptionHero))}</Caption>
        <Button to="/contact" home={true}>
          {buttonText}
        </Button>
      </Hero>
      <section id="#services">
        <Container>
          <h2 style={{ textAlign: "center" }}>
            {ReactHtmlParser(marked(homeText.TitleSuccess))}
          </h2>
          <SuccessDescription style={{ textAlign: "center" }}>
            {ReactHtmlParser(marked(homeText.DescriptionSuccess))}
          </SuccessDescription>
          <ServicesContainer columns={4} gap={1}>
            {services.map(({ node: { Name, name, ImagePrincipal } }) => {
              const url = Name.toLowerCase().split(" ").join("-")

              console.log()

              return (
                <ServiceCard
                  image={ImagePrincipal}
                  alt={name}
                  title={name}
                  to={`/services/${url}`}
                />
              )
            })}
          </ServicesContainer>
        </Container>
      </section>
      <section>
        <Container>
          <Grid columns={2} gap={4}>
            <InnovateContainer>
              <h2>{ReactHtmlParser(marked(homeText.TitleInnovate))}</h2>
              <InnovateDescription>
                {ReactHtmlParser(marked(homeText.DescriptionInnovate))}
              </InnovateDescription>
              <Button to="/contact">{buttonText}</Button>
            </InnovateContainer>
            <Grid
              columns={2}
              responsive={`
              grid-template-columns: 1fr 1fr;
            `}
            >
              {" "}
              {homeText.InnovateCards.map(({ Text }, index) => {
                let isLeft = index % 2 ? false : true

                return <InnovateCard left={isLeft} name={Text} />
              })}
            </Grid>
          </Grid>
        </Container>
      </section>

      <section>
        <Container>
          <h2>{ReactHtmlParser(marked(homeText.TitleCoverage))}</h2>
          <h4 style={{ fontFamily: fuentePrincipal, fontWeight: "normal" }}>
            {ReactHtmlParser(marked(homeText.DescriptionCoverage))}{" "}
          </h4>
          <Grid custom={"2fr 3fr"}>
            <International>
              <br />
              <InternationalTitle>
                <strong>
                  <Counter
                    number={homeText.citiesNumber}
                    isActive={isInternationalActive}
                  />
                </strong>{" "}
                {homeText.citiesText}{" "}
              </InternationalTitle>
              <InternationalTitle>
                <strong>
                  <Counter
                    number={homeText.continetsNumber}
                    isActive={isInternationalActive}
                  />
                </strong>{" "}
                {homeText.continentsText}{" "}
              </InternationalTitle>
            </International>
            <div>
              <Map />
              <p style={{ textAlign: "center", color: colorPrincipal }}>
                <strong
                  css={css`
                    color: ${colorPrincipal};
                  `}
                >
                  {homeText.ProjectsCoverage}
                </strong>
              </p>
            </div>
          </Grid>
        </Container>
      </section>
      <section style={{ background: colorOscuro }}>
        <Container>
          <div
            css={css`
              h2 {
                color: white;
              }
            `}
          >
            {ReactHtmlParser(marked(homeText.TitleProcess))}
          </div>

          <Grid
            columns={3}
            gap={10}
            responsive={`
            gap: 2em;
          `}
          >
            {process.map(({ node: { name, description, Image } }) => (
              <ProcessCard
                title={name}
                image={Image}
                descripcion={description}
              />
            ))}
          </Grid>
        </Container>
      </section>
      <br />
      <br />
      <section style={{ background: colorClaro }}>
        <Container>
          <Grid
            columns={2}
            gap={5}
            align={"center"}
            responsive={`
            gap: 
          `}
          >
            <h2>{ReactHtmlParser(marked(homeText.TitlePartners))} </h2>
            <Banner
              id="partnerts"
              styles={`
              & > div:first-child{
                height: 400px;
              }
              @media (max-width: 500px){
                & > div:first-child{
                  height: 300px;
                }
              }
          `}
            >
              {partners.map(({ node: { Image } }, index) => (
                <PartnerImage className={index === 0 && "active"}>
                  <Img fluid={Image.localFile.childImageSharp.fluid} />
                </PartnerImage>
              ))}
            </Banner>
            <div style={{ filter: "opacity(0.2)" }}></div>
          </Grid>
        </Container>
      </section>

      <section>
        <Container
          style={{
            paddingBottom: 0,
          }}
        >
          <h2
            style={{
              width: "300px",
            }}
          >
            {ReactHtmlParser(marked(homeText.TitleProjects))}{" "}
          </h2>
          <Banner
            id="portfolio"
            styles={`
            transform: translateY(-200px);

            @media (max-width: 500px){
              transform: translateY(0);
            }
          `}
          >
            <PortfolioHome
              title={portfolioText("titleInternet")}
              caption={portfolioText("captionInternet")}
              projects={projectIot}
              className="active"
              to={"/portfolio"}
            />
            <PortfolioHome
              title={portfolioText("titleWeb")}
              caption={portfolioText("captionWeb")}
              projects={projectWeb}
              to={"/portfolio/web"}
            />
            <PortfolioHome
              title={portfolioText("titleMobile")}
              caption={portfolioText("captionMobile")}
              projects={projectMovil}
              to={"/portfolio/mobile"}
            />
            <PortfolioHome
              title={portfolioText("titleCasing")}
              caption={portfolioText("captionCasing")}
              projects={projectCasing}
              to={"/portfolio/casing"}
            />
          </Banner>
        </Container>
      </section>

      <section style={{ background: colorClaro }}>
        <Container
          style={{
            paddingBottom: 0,
          }}
        >
          <h2
            css={css`
              @media (min-width: 700px) {
                width: 500px;
              }
            `}
          >
            {ReactHtmlParser(marked(homeText.TitleTestimonials))}{" "}
          </h2>
          <Banner
            id="testimonials"
            height={heightTestimonial}
            styles={`
            transform: translateY(-200px);

            
            @media (max-width: 500px){
              transform: translateY(0);
              & > div{
                // height: 600px !important;
              }
            }
          `}
            css={``}
          >
            {testimonials.map(({ node }, index) => {
              return (
                <Testimonial
                  setHeightTestimonial={setHeightTestimonial}
                  {...node}
                  className={index === 0 && "active"}
                />
              )
            })}
          </Banner>
        </Container>
      </section>

      <section>
        <Container>
          <Grid custom={"2fr 1fr"}>
            <div>
              <h2
                css={css`
                  font-size: 3rem;
                  font-family: "Roboto", system-ui;
                  font-weight: 900;
                  margin: 0px 0px 1rem;
                `}
              >
                {homeText.TitleContact}
              </h2>
              <h3
                css={css`
                  font-weight: normal;
                  margin: 0;
                  font-size: 1.4rem;
                  color: ${colorPrincipal};

                  @media (max-width: 500px) {
                    margin-bottom: 1em;
                  }
                `}
              >
                {homeText.ContactSubtitle}
              </h3>
            </div>
            <div
              css={css`
                @media (max-width: 500px) {
                  justify-self: center;
                }
              `}
            >
              <Button to="/contact">{buttonText}</Button>
              <ContactSocial>
                <a
                  href={contactItems.WhatsappLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaWhatsapp />
                </a>
                <a
                  href={`mailto:${contactItems.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaRegEnvelope />
                </a>
                <a
                  href={`tel:${contactItems.phoneNumber}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaPhoneAlt />
                </a>
              </ContactSocial>
            </div>
          </Grid>
        </Container>
      </section>
    </>
  )
}

export default Home
