import { graphql, useStaticQuery } from "gatsby"

export const useProjects = projectType => {
  const project = useStaticQuery(
    graphql`
      query {
        allStrapiProjects {
          edges {
            node {
              Name
              project_types {
                Name
              }
              ProjectInfoEn {
                Name
                Description
              }
              ProjectInfoEs {
                Name
                Description
              }
              Image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  let find

  if (projectType) {
    find = project.allStrapiProjects.edges.filter(
      ({ node: { project_types } }) => {
        let isSameType = false
        const types = project_types.map(({ Name }) => Name === projectType)
        const cheackTrue = types.indexOf(true)

        if (cheackTrue !== -1) {
          isSameType = true
        }
        return isSameType
      }
    )
  }
  return find
}
