import React from 'react'
import CardLeft from "../assets/CardLeft";
import CardRight from "../assets/CardRight";
import { CardContainer } from '../styles/components/InnovateCard';

const InnovateCard = ({left, name})=>{

    return(
        <CardContainer left={left}>
            {
                left ? <CardLeft/> : <CardRight/>
            }
            <h3>{name}</h3>
        </CardContainer>
    )
}

export default InnovateCard;