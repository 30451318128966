import { Link } from 'gatsby';
import styled from 'styled-components';
import { colorClaro,colorBorde, fuenteTitulos } from '../variables';

export const PortfolioHomeCardContainer = styled(Link)`
  background: ${colorClaro};
  border: 1px solid ${colorBorde};
  border-radius: 8px;
  text-align: center;
  padding: 2em 3em;
  text-decoration: none;
  @media (max-width: 500px){
    padding: 1em 2em;
  }
  & h4{
      color: #979797;
      text-transform: uppercase;
      margin: 2em 0 0;
      font-family: ${fuenteTitulos};
  }
`;