import React from "react"
import { usePosition } from "../hooks/usePosition"
import {
  ServiceCardContainer,
  ImageService,
} from "../styles/components/ServiceCard"
import Img from "gatsby-image"

const ServiceCard = ({ image, alt, title, to = "/" }) => {
  const isAnimate = usePosition(ServiceCardContainer)

  return (
    <ServiceCardContainer to={to} animate={isAnimate}>
      <ImageService>
        <Img fluid={image.localFile.childImageSharp.fluid} />
      </ImageService>

      <h3>{title}</h3>
    </ServiceCardContainer>
  )
}

export default ServiceCard
