import React,{useEffect, useState} from 'react'
// import { usePosition } from '../hooks/usePosition'

export default function Counter({number, isActive}) {

    const [count, setCount] = useState(0)

    useEffect(() => {
        let timer
        if(isActive){

            timer = setInterval(()=> {
                if(count < number){
                    setCount(count + 1)
                } else{
                    clearInterval(timer)
                }
            },30)
        }

        return () => {
            clearInterval(timer)
        }
    })

    return (
        <>
            {count}
        </>
    )
}
