import { graphql, useStaticQuery } from "gatsby"

export const useTestimonials = () => {
  const testimonials = useStaticQuery(
    graphql`
      query {
        allStrapiTestimonials {
          edges {
            node {
              name
              from
              testimonial
              testimonialShort
              testimonio
              testimonioCorto
              imageFlag {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              imagePerson {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return testimonials.allStrapiTestimonials.edges
}
