import React, {useEffect,useState} from 'react'
import { BannerButton, BannerContainer, BannerItems, ButtonContainer } from '../styles/components/Banner'
import {FaAngleLeft,FaAngleRight} from 'react-icons/fa'

export default function Banner({id,children,style,styles, height = 500}) {

    const [thereNext, setThereNext] = useState(true)
    const [therePrev, setTherePrev] = useState(false)
    const [viewport, setViewport] = useState()

    let $sliderList

    useEffect(()=>{
        $sliderList = document.getElementById(id).children
        if(typeof window !== `undefined`){
            setViewport(window.innerWidth)
        }
    })

    const handleBanner = (next)=>{
        $sliderList = Object.values($sliderList);

        for(let $item of $sliderList){

            let active = $item.classList.contains('active')

            if(active){
                $item.classList.remove('active')
                let nextItem = $item.nextSibling
                let prevItem = $item.previousSibling

                if(next){
                    slide(nextItem, $sliderList[0])
                } else{
                    slide(prevItem,$sliderList[$sliderList.length - 1])
                }

                let actual = $sliderList.find(item => item.classList.contains('active'))
                let isThereNext = actual.nextSibling
                let isTherePrev = actual.previousSibling
                
                isThere(isThereNext,setThereNext)
                isThere(isTherePrev,setTherePrev)
                return
            }
        }

        function slide(to, end){
            if(to){
                to.classList.add('active')
            }else{
                end.classList.add('active')
            }
        }

        function isThere(variable, state){
            if(variable){
                state(true)
            }else{
                state(false)
            }
        }
    }

    return (
        <BannerContainer style={style} styles={styles}>
            <BannerItems id={id} height={((viewport <= 500) && (height <= 500)) ? 600 : height}>
                {children}
            </BannerItems>
            <ButtonContainer>
                <BannerButton left={true} isThere={therePrev}>
                    <button onClick={()=>handleBanner(false)}><FaAngleLeft/></button>
                </BannerButton>
                <BannerButton  isThere={thereNext}>
                    <button onClick={()=>handleBanner(true)}><FaAngleRight/></button>
                </BannerButton>
            </ButtonContainer>
        </BannerContainer>
    )
}

