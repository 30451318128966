import styled from 'styled-components';
import { colorFluerecente, colorOscuro, fuentePrincipal, fuenteTitulos } from '../variables';

export const Person = styled.strong`
  color: ${colorOscuro};
  font-family: ${fuentePrincipal};
  font-size: .7rem;
`;

export const From = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & span{
      color: #283F69;
      text-transform: uppercase;
      font-family: ${fuenteTitulos};
      font-weight: bold;
      margin-left: 2em;
      letter-spacing: 2px;
  }
`;

export const FromImage = styled.div`
  width: 50px;
`;

export const TestimonialLogo = styled.div`
  width: 50%;
  margin: 6em auto 0;
  border-radius: 50%;
  overflow: hidden;

  @media (max-width: 500px){
    margin: 1em auto 0;
  }
`;

export const ReedMore = styled.button`
  position: relative;
  z-index: 1000;
  background: none;
  display: block;
  color: ${colorFluerecente};
  text-decoration: underline;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
`;